<template>
  <v-container>
    <!-- <v-divider></v-divider> -->
    <v-form ref="form">
      <h2 class="title text-center grey--text">
        {{ $t("update package details") }}
      </h2>
      <v-layout row wrap justify-space-between class="mx-auto mb-4">
        <v-flex xs6 sm4 md4 lg4 class="text-left">
          <v-btn
            small
            dark
            outlined
            class="mr-4"
            :color="$store.state.secondaryColor"
            @click="back()"
          >
            <v-icon small left>fas fa-arrow-left</v-icon>
            <span class="caption text-capitalize">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs6 sm4 md4 lg4 class="text-right">
          <v-btn
            small
            dark
            class="mr-4"
            :color="$store.state.primaryColor"
            :loading="loading"
            @click="updatePackage"
          >
            <v-icon small left>save</v-icon>
            <span class="caption text-capitalize">{{ $t("save update") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 class="px-4">
          <v-layout row wrap class="mt-3 caption elevation-1">
            <v-flex xs12 sm6 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("package name") }} :
              </span>
              <v-text-field
                outlined
                dense
                v-model="packages.name"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("package price") }} :
              </span>
              <v-text-field
                outlined
                dense
                v-model="packages.price"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4 lg4 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("business branches") }} :
              </span>
              <v-text-field
                outlined
                dense
                v-model="packages.description.branches"
                :rules="rules.emptyField"
              ></v-text-field> </v-flex
            ><v-flex xs12 sm6 md6 lg6 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("storage size") }}(MB) :
              </span>
              <v-text-field
                outlined
                dense
                v-model="packages.description.storage"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("number of users") }} :
              </span>
              <v-text-field
                outlined
                dense
                v-model="packages.description.users"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3 lg3 class="px-1 align-right">
              <v-color-picker
                dot-size="25"
                swatches-max-height="100"
                mode="hexa"
                elevation="15"
                v-model="packages.packageColor"
              ></v-color-picker>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>

    <v-snackbar
      v-model="snackSuccess"
      top
      centered
      color="success lighten-5 success--text text--darken-3"
      timeout="4000"
    >
      <span>{{ $t("package updated") }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      top
      centered
      color="red lighten-5 error--text text--darken-3"
      timeout="4000"
    >
      <span>{{ $t("could not update package, Try again later") }}.</span>

      <template v-slot:action="{ attr }">
        <v-btn
          icon
          color="error darken-3"
          v-bind="attr"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import db from "@/plugins/fb";
export default {
  data: () => ({
    packages: {},
    loading: false,
    snackSuccess: false,
    snackError: false,
  }),

  created() {
    this.getPackage();
  },
  computed: {
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  methods: {
    getPackage() {
      db.collection("packages")
        .doc(this.$route.params.packageId)
        .get()
        .then((snaps) => {
          this.packages = snaps.data();
        });
    },
    updatePackage() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("packages")
          .doc(this.$route.params.packageId)
          .update(this.packages)
          .then(() => {
            this.snackSuccess = true;
            this.loading = false;
            setTimeout(() => this.$router.push({ name: "Packages" }), 2000);
          });
      }
    },
    back() {
      window.history.back();
    },
  },
};
</script>
